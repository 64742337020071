import i18n from '../../i18n'

export const fillterMenu = [
  {
    id: 1,
    label: 'first_name',
    // name: i18n.t('pages.participantspage.filter-first-name')
    name: 'First name'
  },
  {
    id: 2,
    label: 'last_name',
    // name: i18n.t('pages.participantspage.filter-last-name')
    name: 'Last name'
  },
  {
    id: 3,
    label: 'institution',
    // name: i18n.t('pages.participantspage.filter-company')
    name: 'Institution'
  },
  {
    id: 4,
    label: 'job_title',
    // name: i18n.t('pages.participantspage.filter-job-title')
    name: 'Job Title'
  },
  {
    id: 5,
    label: 'country',
    // name: i18n.t('pages.participantspage.filter-country')
    name: 'Country'
  },
  {
    id: 6,
    label: 'status_online',
    // name: i18n.t('pages.participantspage.filter-status-online')
    name: 'Status online'
  },
  {
    id: 7,
    label: 'attendee_type',
    // name: i18n.t('pages.participantspage.filter-attendee-type')
    name: 'Attendee type'
  }
]
