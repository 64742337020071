import React from 'react'

const EmojiesContainer = ({ showEmojiEmotion, msg, user, msgId, showReactionEmoji, msgEmotion, channel, emojiReaction }) => {
  return (
        <>
            {showEmojiEmotion && msg?.messageId === msgId &&
                <div style={{
                  width: 20,
                  height: 20,
                  display: 'flex',
                  position: 'relative',
                  left: msg?.sender?.userId === user?.userId ? -170 : 0,
                  gap: 5,
                  top: -20
                }}>

                    {emojiReaction?.map((emoji) =>

                        <img
                            className="wight-percent-100 cursor-pointer"
                            onClick={async () => {
                              try {
                                const emojiKey = emoji.key
                                await channel?.addReaction(msgEmotion, emojiKey)
                                showReactionEmoji()
                              } catch (e) {
                                // console.log(e)
                              }
                            }}
                            key={emoji.id}
                            src={emoji.url}
                            alt={emoji.key}/>
                    )}
                </div>

            }
        </>
  )
}

export default React.memo(EmojiesContainer)
