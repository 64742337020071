import React from 'react'
import { useTranslation } from 'react-i18next'
import ButtonMsg from './ButtonMsg'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { downloadAction } from '../../../../../../helper/ButtonHelper/download'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'

const Buttons = ({ msg, editAction, deleteAction, user }) => {
  const { t, i18n } = useTranslation()
  return (
        <>
            {msg?.sender?.userId === user?.userId &&
                <>
                    {!msg?.type ? <ButtonMsg callback={() => editAction()}
                                             icon={<EditIcon/>}
                                             text={t('pages.inboxpage.chats.edit')}/> : ''}
                    <ButtonMsg msg={deleteAction.msg} channel={deleteAction.channel}
                               icon={<DeleteIcon/>} text={t('pages.inboxpage.chats.delete')}/>
                </>
            }
            {msg?.type ? (
                <ButtonMsg callback={() => {
                  downloadAction({ url: msg?.url })
                }} icon={<ArrowCircleDownIcon/>} text={t('pages.inboxpage.chats.download')}/>
            ) : ''}
        </>
  )
}

export default React.memo(Buttons)
